import Axios from '@/modules/axios'
import { watch, onBeforeMount, onBeforeUnmount, onServerPrefetch } from 'vue'
import { storeToRefs } from 'pinia'
import { use as usePageLevel } from '@/stores/pageLevel'
import { useRoute } from 'vue-router'
import { useContext } from 'vite-ssr'

export function usePageLevelInit() {
  const route = useRoute()
  const context = useContext()
  const page = usePageLevel()

  async function load() {
    if (route.params.levels instanceof Array) {
      page.levels = route.params.levels
      const { data } = await Axios.call('meta', route.params.levels.join('/'))
      page.data = data
    }
  }

  onBeforeMount(async () => {
    Object.assign(page, context.initialState.page)
    context.initialState.page = {}
    if (!page.data) await load()
  })
  onServerPrefetch(async () => {
    await load()
    Object.assign(context.initialState, { page: page.$state })
  })
  watch(() => route.params, load)

  onBeforeUnmount(() => page.$reset())

  const { type, buildingType, breadcrumbs, children } = storeToRefs(page)

  return { type, buildingType, breadcrumbs, children }
}
